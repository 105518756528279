<template>
  <div class="Panel">
    <h1 class="text-h5 grey--text text-uppercase">
      <v-btn icon class="align-btn" @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      Relatórios - CENSURAS RESTANTES
    </h1>
    <v-row>
      <v-col cols="12" sm="4" class="mt-4">
        <v-select
          v-model="selectedCampaign"
          :items="campaignsNames"
          label="Campanha"
          item-text="name"
          item-value="id"
          return-object
          dense
          @change="loadSelectedCampaign()"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="campaign">
          <v-card-title>
            {{ campaign.name }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-subtitle v-text="campaign.client"></v-card-subtitle>
          <v-card-text class="">
            <v-row>
              <v-col cols="12">
                <strong>Qtde.:</strong>
                <span v-if="filteredCensures">
                  {{ filteredCensures ? filteredCensures.length : 0 }}
                </span>
                <span v-else>
                  {{ campaign.censuresData ? campaign.censuresData.length : 0 }}
                </span>
                <span class="mx-2"> | </span>
                <strong>P.i.:</strong>
                <span>
                  {{
                    campaign.piCamp
                      ? campaign.piCamp
                      : campaign.censuresData
                      ? campaign.censuresData[0].pi
                      : ''
                  }}
                </span>
                <span class="mx-2"> | </span>
                <strong>Período:</strong>
                <span>
                  {{
                    campaign.timeFrame
                      ? campaign.timeFrame
                      : campaign.censuresData
                      ? campaign.censuresData[0].periodo
                      : ''
                  }}
                </span>
                <span class="mx-2"> | </span>
                <strong>Tipo/Duração:</strong>
                <span>
                  {{
                    campaign.type
                      ? campaign.type
                      : campaign.censuresData
                      ? campaign.censuresData[0].tipo
                      : ''
                  }}/{{
                    campaign.format
                      ? campaign.format
                      : campaign.censuresData
                      ? campaign.censuresData[0].formato
                      : ''
                  }}
                </span>
              </v-col>
              <!-- <v-col
                cols="12"
                sm="6"
                md="5"
                lg="3"
                class="pb-0"
                :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }"
              >
                <p>
                  Conteúdo:
                  <a :href="campaign.contentUrl" target="_blank">{{
                    campaign.censuresData
                      ? campaign.censuresData[0].conteudo
                      : "Texto - T1"
                  }}</a>
                </p>
              </v-col> -->
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-data-table
              :headers="
                currentUser.role == 'admin'
                  ? headers
                  : headers.filter((header) => header.value != 'actions')
              "
              :items="
                filteredCensures ? filteredCensures : campaign.censuresData
              "
              :search="search"
              show-expand
              :expanded.sync="expanded"
              :footer-props="{
                'items-per-page-all-text': 'Todas',
                'items-per-page-text': 'Censuras por página:',
                'items-per-page-options': [15, 50, 100, -1],
              }"
              sort-by="idCamp"
              class="elevation-1"
              item-key="idApp"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>{{ opTitle }}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Pesquisar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  :colspan="headers.length"
                  class="pb-8 pt-6 grey--text text--darken-1"
                >
                  <strong>Programa:</strong> {{ item.programa }}
                  <span class="mx-2"> | </span> <strong>Apresentador:</strong>
                  {{ item.apresentador }} <span class="mx-2"> | </span>
                  <strong>Hor. Início:</strong> {{ item.inicio }}
                  <span class="mx-2"> | </span> <strong>Hor. Fim:</strong>
                  {{ item.fim
                  }}<span class="mx-2" v-if="item.audioData"> | </span
                  ><strong v-if="item.audioData">Operador: </strong>
                  <span v-if="item.audioData">{{
                    item.audioData.operatorName
                  }}</span>
                </td>
              </template>
              <template v-slot:item.audio="{ item }">
                <div v-if="item.audioData">
                  <v-icon
                    small
                    color="green"
                    v-if="currentUser.role == 'admin'"
                  >
                    mdi-play-circle
                  </v-icon>
                </div>
              </template>
              <template v-slot:no-data>
                <p class="mt-4">
                  Nenhuma censura encontrada! Os registros cadastrados
                  aparecerão aqui.
                </p>
              </template>
              <template v-slot:no-results>
                <p class="mt-4">
                  Nenhuma resultado encontrado para busca: {{ search }}
                </p>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions class="pa-5">
            <!-- <v-btn small color="primary" class="mb-2 ml-2" disabled
              >Carregar Planilha</v-btn
            > -->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'reportCensureRemaining',

    mixins: [
      //
    ],

    components: {
      //
    },

    mounted() {
      if (this.currentUser && !this.campaigns)
        this.getUserDataByRole(this.currentUser);
      if (this.campaigns) this.setCampaignItems(this.campaigns);
    },

    destroyed() {
      this.$store.state.campaign = null;
    },

    data() {
      return {
        selectedCampaign: null,
        campaignsNames: [],
        headers: [
          {
            text: 'Id Campanha',
            align: 'start',
            sortable: true,
            value: 'idCamp',
          },
          {
            text: 'Data Veic.',
            sortable: true,
            value: 'veiculacao',
          },
          {
            text: 'Agência',
            sortable: true,
            value: 'agencia',
          },
          {
            text: 'iD Secom',
            sortable: true,
            value: 'idSecom',
          },
          {
            text: 'Pi Veículo',
            sortable: true,
            value: 'piVeiculo',
          },
          {
            text: 'Estado',
            sortable: true,
            value: 'uf',
          },
          {
            text: 'Município',
            sortable: true,
            value: 'municipio',
          },
          {
            text: 'Veículo',
            sortable: true,
            value: 'veiculo',
          },
        ],
        search: '',
        expanded: [],
        opTitle: 'CENSURAS',
        filteredCensures: null,
      };
    },

    computed: {
      campaign() {
        return this.$store.getters.getCampaign;
      },
      campaigns() {
        return this.$store.getters.getCampaigns;
      },
      currentUser() {
        return this.$store.getters.getUser;
      },
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown;
      },
      loading() {
        return this.$store.getters.getLoading;
      },
    },

    watch: {
      currentUser(user) {
        if (user) this.getUserDataByRole(user);
      },
      campaigns(val) {
        if (val) this.setCampaignItems(val);
      },
    },

    methods: {
      getUserDataByRole(user) {
        const id = user.role == 'admin' ? user.id : user.employerId;
        this.$store.dispatch('getAllCampaignsFromUser', id);
      },
      loadSelectedCampaign() {
        // console.log("change", this.selectedCampaign);
        this.opTitle = 'CENSURAS';
        this.filteredCensures = null;
        this.$store
          .dispatch('loadCampaignWithCensuresData', this.selectedCampaign.id)
          .then(() => {
            console.log('campaign', this.campaign);
            this.campaign.censuresData = this.campaign.censuresData.filter(
              (censure) => {
                return !censure.audioData;
              }
            );
          })
          .catch((err) => {
            console.error(err);
          });
      },
      setCampaignItems(campaigns) {
        campaigns.forEach((campaign) => {
          this.campaignsNames.push({
            name: campaign.name,
            id: campaign.id,
          });
        });
        if (this.campaign) {
          this.selectedCampaign = {
            name: this.campaign.name,
            id: this.campaign.id,
          };
        }
      },
    },
  };
</script>

<style scoped>
  .Panel {
    padding: 20px;
  }
  .caption-play {
    transform: translateY(-40px);
  }
</style>
